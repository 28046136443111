body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* FONDO DE SPINGO */
	/* background-image: url('./assets/img/general/fondo_white.png') !important; */
	/* background-repeat: no-repeat !important; */

	background-color: #e7ebf0 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


/* * NAVBAR */

.navbar-item {
	text-decoration: none;
}

/* * NavLink Links */
.navlink-item {
	margin: 0;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.43;
	letter-spacing: 0.01071em;
	color: #1976d2;
	-webkit-text-decoration: underline;
	text-decoration: underline;
	text-decoration-color: rgba(25, 118, 210, 0.4);
}


/* * ONBOARDING ITEM */
.onboarding-item-text {
	text-decoration: none;
	color: #1976d2;
}


.img-ine {
	max-width: 40%;
	height: auto;
	padding: 0;
	margin: 0;
}

.img-ine-modal {
	max-width: 100%;
	height: auto;
	padding: 0;
	margin: 0;
}

.text-decoration-none {
	text-decoration: none;
	color: white;
}

.item-private-layout {
	text-decoration: none;
	color: #000000DE;
}

.main-content {
	min-height: calc(100vh - 150px);
	/* margin-top: 3rem; */
}

.footer-spingo {
	margin-top: 4rem;
	padding: 25px;
	min-height: 40px;
	text-align: center;
	font-size: 14px;
	border-top: 5px solid #3de0a2;
	background: #3340e3;
	color: #ffffff;
}

.footer-spingo-private {
	margin-top: 4rem;
	padding: 25px;
	min-height: 20px;
	text-align: center;
	font-size: 14px;
	border-top: 5px solid #3de0a2;
	background: #3340e3;
	color: #ffffff;
}

.viewer-pdf {
	padding: 0;
	height: 1000px;
	width: 100%;
}

.expedient-view-pdf {
	padding: 0;
	text-align: center;
}


.register-login-card {
	background: #FFFFFF;
	box-shadow: 0px 4px 12px -4px rgba(51, 64, 227, 0.08), 0px 12px 24px -6px rgba(38, 39, 47, 0.1);
	border-radius: 20px;
}

.login-terms {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 160%;
	/* Neutrals/70 */
	color: #707F98;
	/* Inside auto layout */
	flex: none;
	order: 2;
	flex-grow: 0;
}

.cursor-pointer {
	cursor: pointer;
}